import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'

const mapStateToProps = store => {
    const { updateButtonText } = store.mainState
    return { updateButtonText }
}

function UpdateAdmin({ updateButtonText, item }) {
    const dispatch = useDispatch();
    const [myRoleId, setMyRoleId] = useState(1);
    const [myAdminName, setMyAdminName] = useState("");
    const [myEmail, setMyEmail] = useState("");
    const [myPassword, setMyPassword] = useState("");
    const [myPhoneNo, setMyPhoneNo] = useState("");
    const [myGender, setMygender] = useState("MALE")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [deviceMac, setMyDeviceMac] = useState("")
    const [deviceIp, setMyDeviceIp] = useState("")

    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
         dispatch({
            type: "UPDATE_ADMIN_USERS_REQUEST",
            payload: {
                "emailId": myEmail,
                "fullName": myAdminName,
                "mobileNumber": myPhoneNo,
                "roleId": myRoleId,
                "dateOfBirth": dateOfBirth,
                "deviceMac": deviceMac,
                "deviceIP": deviceIp,
                "gender": myGender
            }
        })
    }
    useEffect(() => {
        setMyAdminName(item.fullName)
        setMyPhoneNo(item.mobileNumber)
        setMyRoleId(item.roleId)
    }, [item])
    return (
        <div>
            <div className="Modal--heading">
                Update Admin
            </div>
            <div className="Modal--form">
                <form>

                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Admin Name: </label><input type="text" value={myAdminName} placeholder="Admin Name..." onChange={(e) => setMyAdminName(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Email: </label><input type="email" value={myEmail} placeholder="Email...." onChange={(e) => setMyEmail(e.target.value)} required /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Phone No: </label><input type="text" value={myPhoneNo} placeholder="Admin PhoneNo..." onChange={(e) => setMyPhoneNo(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Gender: </label>
                            <select value={myGender} onChange={(e) => setMygender(e.target.value)}>
                                <option value="MALE">Male</option>
                                <option value="FAMALE">Famale</option>
                            </select>
                        </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Date Of Birth: </label><input type="text" value={dateOfBirth} placeholder="dd-MM-yyy format" onChange={(e) => setDateOfBirth(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Device Mac: </label><input type="text" value={deviceMac} placeholder="Device Mac" onChange={(e) => setMyDeviceMac(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Device Ip: </label><input type="text" value={deviceIp} placeholder="Device Ip" onChange={(e) => setMyDeviceIp(e.target.value)} /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {updateButtonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(UpdateAdmin)